import { logEvent } from "firebase/analytics";
import { analytics } from "src/config/configFirebase";
import { Dock, DockIcon } from "@components/base/Dock";

export function SocialNetworks() {
  const handleSocialClick = (social) => {
    logEvent(analytics, "social_click", {
      platform: social,
    });
  };

  return (
    <Dock>
      <DockIcon>
        <a
          href="https://www.facebook.com/profile.php?id=61559768376627&mibextid=ZbWKwL"
          target="_blank"
          onClick={() => handleSocialClick("Facebook")}
        >
          <i className="iconify w-5 h-5" data-icon="fa6-brands:facebook"></i>
        </a>
      </DockIcon>
      <DockIcon>
        <a
          href="https://www.instagram.com/pawfolio.pet/?hl=es"
          target="_blank"
          onClick={() => handleSocialClick("Instagram")}
        >
          <i className="iconify w-5 h-5" data-icon="fa6-brands:instagram"></i>
        </a>
      </DockIcon>
      <DockIcon>
        <a
          href="https://www.tiktok.com/@pawfolio.pet"
          target="_blank"
          onClick={() => handleSocialClick("TikTok")}
        >
          <i className="iconify w-4 h-4" data-icon="fa6-brands:tiktok"></i>
        </a>
      </DockIcon>
    </Dock>
  );
}
